import React, { useReducer, useEffect } from 'react';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'

import axios from 'axios'

import reducer from '../reducers'
import TodoForm from './TodoForm';
import TodoList from './TodoList'
import { GET_TODO } from '../actions'


const GET_URL = 'https://xbf11ml3nj.execute-api.ap-northeast-1.amazonaws.com/Demo-ToDo/demotodo/get'

const Todo = () => {
  const [state, dispatch] = useReducer(reducer, [])

  useEffect(() => {
    getDB()
  }, [])

  const getDB = async () => {
    const response = await axios.get(GET_URL)
    const todoList = response.data.Items.map(value => value)
    for (let _todo in todoList) {
      dispatch({ type: GET_TODO, ...todoList[_todo] })
    }
  }

  return (
    <React.Fragment>
      <div className="ToDo">
        <div className='container'>
          <TodoForm state={state} dispatch={dispatch} />
          <TodoList state={state} dispatch={dispatch} />
          <div className='footer'>
            <p className="text-center mb-4"># 2021 QES AWS-Serverless Demo Environment.</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Todo;
