import axios from 'axios'

import { GET_TODO, CREATE_TODO, DELETE_TODO, DELETE_ALL_TODOS, UPDATE_TODO } from '../actions'


const POST_URL = 'https://xbf11ml3nj.execute-api.ap-northeast-1.amazonaws.com/Demo-ToDo/demotodo/post'
const DELETE_URL = 'https://xbf11ml3nj.execute-api.ap-northeast-1.amazonaws.com/Demo-ToDo/demotodo/delete'

const todos = (state = [], action) => {
  switch(action.type) {
    case GET_TODO:
      let data = [...state, { id: action.id, ToDo: action.ToDo, Completed: action.Completed}]
      data.sort(function(first, second) {
        if(first.id > second.id) {
          return 1
        } else {
          return -1
        }
      })
      return data
    case CREATE_TODO:
      const todo = { ToDo: action.Todo }
      const length = state.length
      let id
      if(length === 0) {
        id = 1
      } else {
        id = state[length - 1].id + 1
      }
      const putData = { id: id, ...todo, Completed: false }
      const jsonPutData = JSON.stringify(putData)
      axios.post(POST_URL, jsonPutData)
      const dataTodo = [...state, { id: id, ...todo, Completed: false }]
      return dataTodo
    case DELETE_TODO:
      const deleteItem = { key: action.id }
      axios.delete(DELETE_URL, { data: { ...deleteItem } })
      return state.filter(event => event.id !== action.id)
    case DELETE_ALL_TODOS:
      for (let deleteKey in action.state) {
        const deleteItem = { key: action.state[deleteKey].id }
        axios.delete(DELETE_URL, { data: { ...deleteItem } })
      }
      return []
    case UPDATE_TODO:
      const updateData = { id: action.id, ToDo: action.ToDo, Completed: action.Completed }
      const jsonUpdateData = JSON.stringify(updateData)
      axios.post(POST_URL, jsonUpdateData)
      for (let changeCompleted in state) {
        if(state[changeCompleted].id === action.id) {
          state[changeCompleted] = updateData
        }
      }
      return [...state]
    default:
      return state
  }
}

export default todos