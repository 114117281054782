import React from "react"

import TodoEvent from './TodoEvent'

const TodoList = ({ state, dispatch }) => {
  return (
    <table className='table table-hover'>
      <thead>
        <tr>
          <th>ID</th>
          <th>Todo</th>
        </tr>
      </thead>
      <tbody>
        { state.map((value) => (<TodoEvent key={value.id} event={value} dispatch={dispatch} />))}
      </tbody>
    </table>
  )
}

export default TodoList