import React, { useState } from "react";

import { CREATE_TODO, DELETE_ALL_TODOS } from "../actions";


const TodoForm = ({ state, dispatch }) => {
  const [todo, setTodo] = useState('')

  const addTodo = e => {
    e.preventDefault()
    dispatch({ type: CREATE_TODO, Todo: todo })

    setTodo('')
  }

  const allDelete = e => {
    e.preventDefault()
    const result = window.confirm('全てのTodoを削除しますか？')
    if(result) {
      dispatch({ type: DELETE_ALL_TODOS, state})
    }
  }

  const unCreatable = todo === ''

  return (
    <React.Fragment>
      <h1 className="text-center mb-4">Demo App</h1>
      <form className="br">
        <div className='form-group'>
          <label htmlFor='formAddToDo'><b>Add Todo</b></label>
          <input className='form-control' id='formAddToDo' value={todo} onChange={e => setTodo(e.target.value)} placeholder="Todoを入力" />
          <button className='btn btn-primary' type='submit' onClick={addTodo} disabled={unCreatable}>Todoを作成する</button>
          <button className="btn btn-danger" onClick={allDelete} disabled={state.length === 0}>全てのTodoを削除する</button>
        </div>
      </form>
    </React.Fragment>
  )
}

export default TodoForm