import React from "react"
import { Button } from 'react-bootstrap'

import { DELETE_TODO, UPDATE_TODO } from "../actions"


const TodoEvent = ({ event, dispatch }) => {
  const id = event.id
  const todo = event.ToDo

  const handleClickDeleteButton = () => {
    dispatch({
      type: DELETE_TODO,
      id: id
    })
  }

  const taskCompleted = () => {
    if(event.Completed === false) {
      dispatch({
        type: UPDATE_TODO,
        id: id,
        ToDo: todo,
        Completed: true
      })
    } else {
      dispatch({
        type: UPDATE_TODO,
        id: id,
        ToDo: todo,
        Completed: false
      })
    }
  }

  return (
    <tr className="todoevent">
      <td className="noborder">{id}</td>
      <td className="noborder" style={{ textDecoration: event.Completed ? "line-through" : "" }}>{todo}</td>
      <td className="button">
        <Button variant="outline-success" onClick={taskCompleted}>✓</Button>{' '}
        <Button variant="outline-danger" onClick={handleClickDeleteButton}>✕</Button>
      </td>
    </tr>
  )
}

export default TodoEvent